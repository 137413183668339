<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="姓名">
        <el-input v-model="search.keywords" placeholder="请输入姓名" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <!--div class="mg-bt-10">
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delGroup()">删除</el-button>
    </div-->
    <el-table
            :data="tableData"
            ref="rfTable"
            border
            style="width: 100%"
            size="mini"
            @selection-change="handleSelectionChange">
      <!--el-table-column
              type="selection"
              align="center"
              width="55">
      </el-table-column-->
      <el-table-column
              prop="id"
              label="ID"
              align="center"
              width="100">
      </el-table-column>
      <el-table-column
              prop="real_name"
              label="姓名">
      </el-table-column>
      <el-table-column
              prop="name"
              label="昵称"
              align="center"
              width="180">
      </el-table-column>
      <el-table-column
              prop="email"
              label="邮箱"
              align="center"
              width="160">
      </el-table-column>
      <el-table-column
              prop="mobile"
              label="手机号"
              align="center"
              width="160">
      </el-table-column>
      <el-table-column
              prop="school"
              label="所属学校"
              align="center"
              width="120">
      </el-table-column>
      <el-table-column
              prop="faculty"
              label="院系"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              prop="limit_course_sum"
              label="最多创建课程"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              prop="limit_class_sum"
              label="最多加入班积"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              prop="create_time"
              label="添加时间"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="130">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editLimit(scope.row)">编辑</el-button>
          <el-button type="text" size="small" @click="getCoursePackage(scope.row)">领取课程包</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      layout="total, pager, next, jumper"
      :page-size="search.limit"
      :total="search.total">
    </el-pagination>
    <el-dialog title="编辑信息" :visible.sync="dialogFormVisible" custom-class="dialog">
      <el-form :model="form" size="small">
        <el-form-item label="最多创建课程数" label-width="120px">
          <el-input v-model="form.limit_course_sum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="最多加入班级数" label-width="120px">
          <el-input v-model="form.limit_class_sum" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveUser" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="领取课程包" :visible.sync="isShowPackage" custom-class="dialog">
      <el-form :model="packageForm" size="small">
        <el-form-item label="选择课程包" label-width="120px">
          <el-select v-model="packageForm.courseId" placeholder="请选择">
            <el-option
                v-for="item in packageList"
                :key="item.course_id"
                :label="item.course_name"
                :value="item.course_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人数" label-width="120px">
          <el-input-number v-model="packageForm.peopleNumber" :min="1" :max="10000" autocomplete="off" style="width: 200px"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowPackage = false" size="small">取 消</el-button>
        <el-button type="primary" @click="getPackage" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 15,
        keywords: ''
      },
      tableData: [],
      dialogFormVisible: false,
      isShowPackage: false,
      form: false,
      packageForm: {
        courseId: '',
        memberId: 0,
        peopleNumber: 1
      },
      packageList: []
    };
  },
  created() {
    this.getPackageList()
    this.getList();
  },
  mounted() {
    //
  },
  computed: {
  },
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords
      }
      this.$api.merchant.teacherIndex(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    getPackageList() {
      this.$api.course.coursePackageIndex({pageSize:100,status:2},res => {
        if(res.errcode == 0) {
          this.packageList = res.data
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    },
    editLimit (item) {
      this.form = JSON.parse(JSON.stringify(item))
      this.dialogFormVisible = true
    },
    getCoursePackage (item) {
      this.packageForm.memberId = item.id
      this.isShowPackage = true
    },
    saveUser () {
      var that = this
      that.showLoading()
      var param = this.form
      this.$api.merchant.teacherEdit(param, function (res) {
        that.hideLoading()
        that.dialogFormVisible = false
        if(res.errcode == 0) {
          that.success(res.errmsg)
        } else {
          that.fail(res.errmsg)
        }
        that.getList()
      })
    },
    getPackage () {
      if(this.packageForm.courseId == '') {
        this.tips('请选择课程包')
        return false
      }
      this.showLoading()
      this.$api.course.coursePackageSend(this.packageForm, res => {
        this.hideLoading()
        this.isShowPackage = false
        if(res.errcode == 0) {
          this.success('领取成功')
        } else {
          this.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
</style>
